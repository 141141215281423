import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import getAppUrl from "@src/utils/getAppUrl"

import ConditionalIfElseIcon from "@src/icons/v2/conditional-if-else.svg"
import ServerlessFunctionsIcon from "@src/icons/v2/serverless-functions.svg"
import SendEmailsIcon from "@src/icons/v2/send-emails.svg"
import LoopingLogicIcon from "@src/icons/v2/looping-logic.svg"
import InvokeTriggerBuildsIcon from "@src/icons/v2/invoke-trigger-builds.svg"
import TriggerWebhooksIcon from "@src/icons/v2/trigger-webhooks.svg"
import IntegrationsIcon from "@src/icons/v2/integrations-alt.svg"
import WorkflowScreenshot from "@src/icons/screenshots/workflow-screenshot.png"
import builtInCodeEditor from "@src/icons/screenshots/built-in-code-editor.png"
import CronJobs from "@src/icons/screenshots/cron-job.png"
import ChainedWebhooks from "@src/icons/screenshots/chained-webhooks.png"
import OG from "@src/icons/og/OG_Workflow.jpeg"

import * as styles from "./styles.module.scss"
import Splash from "./components/Splash"
import Row from "../components/Row"
import ImageRow from "./components/ImageRow"
import Features from "./components/Features"

const IndexPage = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="Build experiences with low-code workflow automation"
      description="Deliver value to your users by defining your business logic using simple blocks on our intuitive low-code platform."
      url="https://canonic.dev/features/workflows"
      location={location}
      ogImage={OG}
    >
      <Splash
        screenshot={WorkflowScreenshot}
        title="Build workflows with custom logic."
        subtitle="Deliver custom experiences to your users by defining your business logic in a graphical interface."
      />
      <Row>
        <ImageRow
          title="Complete with a built-in code editor."
          subtitle="Complex business logic that just has to be coded out? Don’t worry, we’ve got you covered with our built-in code editor."
          screenshot={builtInCodeEditor}
        />
      </Row>
      <Row>
        <ImageRow
          title="Use Cron triggers to automate notifications."
          subtitle="Automate notifications or messages by using Cron triggers and improve communication with your users or your team."
          screenshot={CronJobs}
          reverse
        />
      </Row>

      <Row>
        <ImageRow
          title="All your favourite integrations."
          subtitle="Create an efficient workflow by integrating services from your favourite providers."
          icon={IntegrationsIcon}
        />
      </Row>
      <Row>
        <ImageRow
          title="Create a chain of webhooks"
          subtitle="Trigger multiple webhooks from a single endpoint by creating a chain of webhooks."
          screenshot={ChainedWebhooks}
          reverse
        />
      </Row>
      <Row>
        <h2 className={styles.title}>Complex workflows, minimal effort.</h2>
        <p className={styles.subtitle}>Top benefits</p>
        <Features
          numPerRow={3}
          features={[
            {
              title: "Trigger webhooks ",
              subtitle:
                "Trigger a webhook whenever an API is invoked to perform an operation.",
              icon: TriggerWebhooksIcon,
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },

            {
              title: "Looping logic ",
              subtitle:
                "Run actions in a loop until a specific condition is met.",
              icon: LoopingLogicIcon,
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Conditional if-else",
              subtitle:
                "Define actions based on whether a condition is true or false.",
              icon: ConditionalIfElseIcon,
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Send Emails/Messages/SMS",
              subtitle:
                "Send out communication by using our Twilio, Mailgun or Slack integrations.",
              icon: SendEmailsIcon,
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Invoke Builds",
              subtitle:
                "Support for Travis, GitLab, BitBucket and Circle CI to trigger builds whenever necessary.",
              icon: InvokeTriggerBuildsIcon,
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Serverless Functions ",
              subtitle:
                "Trigger both, Google Serverless functions or AWS Lambda functions directly from our platform.",
              icon: ServerlessFunctionsIcon,
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
          ]}
        />
      </Row>
      <Callout location={location} title="workflows" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
